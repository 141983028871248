<script setup>
import { ToastProgrammatic } from 'buefy';
import { computed, ref } from 'vue';

import EmailInput from '@/components/EmailInput.vue';
import linksApiService from '@/services/links-api-service';
import { useDashboardStore } from '@/stores/dashboard-store';
import { makeEmailInputDataStore } from '@/utils/helpers/email-input-helpers';
import { isValidEmail } from '@/utils/helpers/input-validation-helpers';

const emailInputData = makeEmailInputDataStore();
const dashboardStore = useDashboardStore();

const emit = defineEmits(['close']);
const props = defineProps({
    linkObject: Object
})
const newOwnerEmail = ref('');
const isSaving = ref(false);

const onCancelClick = () => emit('close');
const onSaveClick = async () => {
    isSaving.value = true;

    try {
        await linksApiService.transferLinkOwnership(props.linkObject.id, newOwnerEmail.value);
        dashboardStore.gridApi.applyTransaction({ remove: [props.linkObject] });

        ToastProgrammatic.open({
            message: `Transfer ownership success`,
            position: 'is-top',
            type: 'is-success',
            duration: 2500,
        });

        if (window.caches) {
            const cache = await caches.open(`hrbr-${import.meta.env.VITE_SERVICE_WORKER_VERSION}`);
            await cache.delete('/core/myLinks')
        }

        emit('close');
    } catch (err) {
        // Check if it's an error thrown by us instead of Pydantic's validation
        // In such case we do want to show a user friendly message
        const isParamValidationError = !!err.response?.data?.detail;
        if (err.response.status === 400 && !isParamValidationError) {
            ToastProgrammatic.open({
                message: err.response.data.messages[0],
                position: 'is-top',
                type: 'is-danger',
                duration: 2500,
            });
        } else {
            ToastProgrammatic.open({
                message: `Something went wrong...`,
                position: 'is-top',
                type: 'is-danger',
                duration: 2500,
            });
        }
    } finally {
        isSaving.value = false;
    }
};

const isEmailAllowed = computed(() => isValidEmail(newOwnerEmail.value));
</script>

<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">
                <b-icon class="transfer-icon" pack="fal" icon="arrow-right-arrow-left" size="is-small"
                    title="Transfer ownership" alt="Transfer ownership" />
                <span>Transfer ownership</span>
            </p>
        </header>
        <section class="modal-card-body">
            <div>
                Once you transfer ownership you won't have access to <strong>{{ linkObject.title }}.</strong> The
                contract's new owner will be notified.
            </div>
            <div class="modal-card-input">
                <b-field>
                    <EmailInput
                        placeholder="Search by name or email"
                        v-model="newOwnerEmail"
                        icon="magnifying-glass"
                        :data-store="emailInputData"
                        :results-limit="30"
                        :recents-tags-limit="5"
                        :show-self-in-recents="false"
                        :hide-recent-tags="true"
                        :org-users-only="true"
                        :is-compact="true"
                    />
                </b-field>
            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button @click="onCancelClick">Cancel</b-button>
            <b-button :disabled="isSaving || !isEmailAllowed" @click="onSaveClick" type="is-primary" class="save-button">
                <i v-if="isSaving" class="fal fa-spinner-third fa-spin" data-testid="spinner"></i>
                <span v-else>Transfer</span>
            </b-button>
        </footer>
    </div>
</template>

<style>
.modal-content {
    overflow: visible;
}
</style>
<style scoped>
.modal-card {
    width: auto;
    overflow: visible;
}

.modal-card-body {
    padding: 32px;
    overflow: visible;
}

.modal-card-input {
    padding: 32px 0;
}

.transfer-icon {
    margin-right: 12px;
}

.save-button {
    width: 90px;

    &:disabled {
        background-color: var(--main-primary-color)
    }
}
</style>
