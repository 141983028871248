<script>
export default {
  props: [
    'label',
    'resultsLimit',
    'recentTagsLimit',
    'noDebug',
    'emailStore',
    'hideRecentTags',
    'showSelfInRecents',
    'isCompact',
    'inputReference',
    'nameInputKey',
    'emailInputKey',
    'isAutoFocus',
    'currentUserNameAutoFocus',
    'currentUserEmailAutoFocus',
    'orgUsersOnly',
    'disabled',
    'icon',
    'iconPack',
    'withCcIcons',
    'hideIconOnType'
  ],

  data() {
    return {
      matches: [],
      currentSearchString: '',
      currentUserEmail: '',
      showingAllRecentEmails: false,
      usingArrayForEmailStorage: false,
      profilePictureStyles: {
        width: '40px',
        height: '40px',
        marginRight: '1em',
        borderRadius: '50%',
        backgroundColor: '#f5f5f5',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      dataStore: {
        emailsToNames: {},
        namesToEmails: {},
        emailsToProfileImageUrls: {},
        recentEmails: [],
        deletedEmails: [],
        orgUsers: [],
      },
      localStorageKey: 'hrbr_emailInputData',
      isAutoFocusLocal: this.isAutoFocus,
      ccAdded: false,
      bccAdded: false
    };
  },

  methods: {
    keydownHandler(value) {
      this.updateMatches(value);
      this.currentSearchString = value;
      this.$emit('email-input-keydown', value);
    },

    enterKeyHandler() {
      this.$emit('email-input-enter', this.currentSearchString);
    },

    onBlurHandler() {
      if (this.usingArrayForEmailStorage) {
        this.keydownHandler(this.currentSearchString);
        const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegExp.test(this.currentSearchString)) {
          this.enterKeyHandler();
          this.$refs.emailInputAutoComplete.addTag();
        }
      }
      this.$emit('email-input-blur', this.currentSearchString);
    },

    processEmailInput(emailText) {
      const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      let processedEmailText = emailText;
      if (emailRegExp.test(processedEmailText)) {
        processedEmailText = processedEmailText.toLowerCase();
      }

      return processedEmailText
    },

    onInputHandler(evt) {
      // the component is used in multiple places. Input might be expected as a string or array
      if (typeof evt === 'string'){
        const processedEmailText = this.processEmailInput(evt);
        this.$emit('input', processedEmailText);
      }
      else {
        let emailsToAdd = [];

        for (const ev of evt) {
          const processedEmailText = this.processEmailInput(ev);
          emailsToAdd.push(processedEmailText);
        }
        this.$emit('input', emailsToAdd);
      }
    },

    onPasteHandler(evt) {
      const email_str = evt.clipboardData.getData('text');
      let emails = email_str.split(/[\n,]+/);
      for (const email of emails) {
        const lowerCaseEmail = email.toLowerCase();
        this.updateMatches(lowerCaseEmail);
        this.currentSearchString = lowerCaseEmail;
        this.$emit('email-input-enter', lowerCaseEmail);
        if (this.usingArrayForEmailStorage) {
          this.$refs.emailInputAutoComplete.addTag(lowerCaseEmail);
        }
      }
    },

    updateMatches(value) {
      if (value === '') {
        return;
      }

      this.matches = [];

      // no duplicates
      const seen = new Set();

      // find and store matching emails
      const matchingEmails = Object.keys(this.dataStore.emailsToNames)
        .filter((email) => email.toLowerCase()
          .includes(value.toLowerCase()));

      matchingEmails.forEach((email) => {
        if (!this.emailIsValid(email) || seen.has(email)) {
          return;
        }

        seen.add(email);

        this.matches.push({
          name: this.dataStore.emailsToNames[email],
          email,
        });
      });

      // find matching names and store corresponding emails
      const matchingNames = this.sanitizeArray(Object.keys(this.dataStore.namesToEmails)
        .filter((name) => name.toLowerCase()
          .includes(value.toLowerCase())));

      matchingNames.forEach((name) => {
        let skip = false;
        this.dataStore.namesToEmails[name].forEach((email) => {
          // if user has been seen already
          if (seen.has(email)) {
            skip = true;
            return;
          }
        });

        if (skip) {
          return;
        }

        seen.add(this.dataStore.namesToEmails[name]);

        this.dataStore.namesToEmails[name].forEach((email) => {
          this.matches.push({
            name,
            email,
          });
        });
      });
      // limit number of results
      const limit = this.resultsLimit || 20;
      this.matches = this.matches.slice(0, limit);

      if (this.orgUsersOnly) {
        this.matches = this.matches.filter(({ email }) => this.dataStore.orgUsers.includes(email));
      }
    },

    loadCurrentUserEmail() {
      this.getContextDict(() => {
        const email = this.contextDict?.systememail;
        const name = this.contextDict?.userinfo_userfullname;

        this.currentUserEmail = email;

        this.dataStore.emailsToNames[email] = name;

        const existingEmails = !this.dataStore.namesToEmails[name] ? [] : this.dataStore.namesToEmails[name];
        this.dataStore.namesToEmails[name] = this.sanitizeArray([...existingEmails, email]);
      }, () => {
        this.debug('Couldn\'t load current user email');
      });
    },

    // remove duplicates and nulls
    sanitizeArray(arr) {
      return [...new Set(arr)].filter((i) => i);
    },

    getUserInitials(name) {
      const fullName = name.split(' ');
      const firstInitial = fullName[0][0];
      const lastInitial = fullName[1] ? fullName[1][0] : '';

      return (firstInitial + lastInitial).toUpperCase();
    },

    // insert <b> tags into string around search string
    emphasizeSearchString(text) {
      if (!text) {
        return '';
      }

      // find beginning and end of search string as substring
      const beg = text.toLowerCase()
        .indexOf(this.currentSearchString.toLowerCase());
      if (beg === -1) {
        return text;
      }
      const end = beg + this.currentSearchString.length;

      // build new string
      const prefix = text.slice(0, beg);
      const infix = `<b>${text.slice(beg, end)}</b>`;
      const postfix = text.slice(end);

      return prefix + infix + postfix;
    },

    processCurrentUserOrgEmails(data) {
      console.log('processCurrentUserOrgEmails', data);
      this.debug('loaded org emails', data);
      // org member emails
      // there are no user names in this data, only emails
      data.organizationgroupmember.forEach((email) => {
        this.dataStore.emailsToNames[email] = null;
      });

      // org admin emails (if present)
      if (data.currentOrganizationAdminInfo.length) {
        const orgAdminInfo = data.currentOrganizationAdminInfo[0];
        orgAdminInfo.organizationadmins_systemuseruniqueharbourdemailsarray.forEach((email) => {
          this.dataStore.emailsToNames[email] = null;
        });
      }
      this.dataStore.orgUsers = data.organizationgroupmember;
    },

    processWhiteListedUsers(data) {
      this.debug('loaded whitelisted users', data);
      const { userArray } = data;
      userArray.forEach((user) => {
        this.dataStore.emailsToNames[user.email] = user.name;

        const existingEmails = this.dataStore.namesToEmails[user.name];
        this.dataStore.namesToEmails[user.name] = existingEmails ? this.sanitizeArray([...existingEmails, user.email]) : [user.email];
      });
    },

    processRecentContacts(data) {
      this.debug('loaded recent contacts', data);
      // reverse array to get most recent contacts first
      const { contactsArray } = data;
      const emails = [];

      contactsArray.forEach((user) => {
        this.dataStore.emailsToNames[user.email] = user.name;

        const existingEmails = this.dataStore.namesToEmails[user.name];
        this.dataStore.namesToEmails[user.name] = existingEmails ? this.sanitizeArray([...existingEmails, user.email]) : [user.email];

        // add to array for tag display
        emails.push(user.email);
      });

      // save emails
      this.dataStore.recentEmails.push(...this.sanitizeArray(emails));
    },

    async loadAllAvailableEmails() {
      this.debug('loading all email data');

      this.dataStore.emailsToNames = {};
      this.dataStore.namesToEmails = {};
      this.dataStore.emailsToProfileImageUrls = {};
      this.dataStore.recentEmails = [];

      this.loadCurrentUserEmail();

      const endpoints = [
        // org emails
        {
          endpoint: 'data?settings-getstoredvalues',
          // endpoint: 'api/user_groups',
          requesttype: 'settings-getstoredvalues',
          dataHandler: this.processCurrentUserOrgEmails,
        },
        // whitelisted users
        {
          endpoint: 'data?account-getusersbywhitelisteddomain',
          requesttype: 'account-getusersbywhitelisteddomain',
          dataHandler: this.processWhiteListedUsers,
        },
        // recent contacts
        {
          endpoint: 'data?account-getrecentcontacts',
          requesttype: 'account-getrecentcontacts',
          dataHandler: this.processRecentContacts,
        },
      ];

      const responses = await Promise.all(endpoints.map(async (e) => {
        try {
          const resp = await fetch(e.endpoint, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ requesttype: e.requesttype }),
            withCredentials: true,
          });
          if (!resp.ok) {
            this.debug('could not fetch', e.endpoint);
            return null;
          }
          return resp;
        } catch (err) {
          this.debug('could not fetch', e.endpoint, err);
          return null;
        }
      }));

      const jsonArray = await Promise.all(responses.map((r) => r?.json() || null));
      jsonArray.forEach((json, i) => json ? endpoints[i].dataHandler(json) : null);

      // fetching the emails that user previously deleted
      await this.fetchDeletedEmails();

      // don't wait for profile picture load
      this.$emit('email-load-done', this.dataStore);

      this.$emit('profile-picture-load-initiated');

      let profilePicResp = null;
      try {
        profilePicResp = await fetch('/account-getprofilepicture', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            requesttype: 'account-getprofilepicture',
            emailsarray: Object.keys(this.dataStore.emailsToNames),
          }),
          withCredentials: true,
        });
      } catch (err) {
        this.debug('could not fetch profile pictures', err);
      }

      const profilePicJson = await profilePicResp?.json() || { userdictlist: [] };

      this.debug('loaded profile pictures', profilePicJson);
      this.$emit('profile-picture-load-done', this.dataStore);
      profilePicJson.userdictlist.filter((u) => u.profile_image_url)
        .forEach((u) => {
          this.dataStore.emailsToProfileImageUrls[u.emails[0]] = u.profile_image_url;
        });

      // cache email data in localStorage
      const cacheSuccess = this.cacheEmailData();
      this.debug(cacheSuccess ? 'cached contact data' : 'couldn\'t cache contacts');
    },

    async fetchDeletedEmails() {
      const resp = await fetch('data?account-getautocompleteemailstohide', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          requesttype: 'account-getautocompleteemailstohide',
        }),
        withCredentials: true,
      });

      if (!resp.ok) {
        this.debug('could not hide an email');
        return null;
      }
      const respJson = await resp.json();
      this.dataStore.deletedEmails = respJson.emailstohide;
    },

    // handle clicking "recent" tag (email tags below input)
    recentsTagClickHandler(email) {
      // store clicked email
      if (Array.isArray(this.$props.emailStore)) {
        this.$props.emailStore.push(email);
      }
      // remove from available recents
      this.dataStore.recentEmails = this.dataStore.recentEmails.filter((e) => e !== email);

      this.$emit('email-input-recent-tag-select', email);
    },

    // handle autocomplete dropdown item clicks
    dropdownClickHandler(email) {
      if (!email) {
        return;
      }
      // update linked email or name
      const name = this.dataStore.emailsToNames[email] || null;
      this.updateEmailAndName(email, name);
      this.$emit('email-input-select', email);
      // If not multiple email input, close dropdown since user has already selected an email
      if (!this.usingArrayForEmailStorage && this.$refs?.emailInputAutoComplete?.$refs?.autocomplete) {
        this.$refs.emailInputAutoComplete.$refs.autocomplete.isActive = false;
      }
    },

    // update linked email or name input
    updateEmailAndName(email, name) {
      if (!this.$props.inputReference) {
        return;
      }
      // set email
      if (this.emailInputKey) {
        this.$props.inputReference[this.emailInputKey] = email;
      }
      // set name
      if (this.nameInputKey) {
        // save for after select if selected contact has no name
        const lastNameValue = this.inputReference[this.nameInputKey];
        // setTimeout for when selecting from input itself
        setTimeout(() => {
          // overwrite name if blank or email
          const currentNameValue = this.inputReference[this.nameInputKey];
          if (!currentNameValue || this.emailIsValid(currentNameValue)) {
            this.$props.inputReference[this.nameInputKey] = name || lastNameValue;
          }
        }, 10);
      }
    },

    // check for valid email format
    emailIsValid(email) {
      if (!email.includes('@') || !email.includes('.')) {
        return false;
      }
      return true;
    },

    // cache in local storage
    cacheEmailData() {
      let emailDataString = JSON.stringify(this.dataStore);

      // must be less than 5MB/2 (each char 2 bytes wide)
      const localStorageLimit = 5 * 2 ** 19;
      if (emailDataString.length >= localStorageLimit) {
        // string too long, try for recent contacts only
        const recentContactData = {
          emailsToNames: {},
          namesToEmails: {},
          emailsToProfileImageUrls: {},
          recentEmails: [...this.dataStore.recentEmails],
        };
        // store recent contact data
        this.dataStore.recentEmails.forEach((email) => {
          // emails to names
          const name = this.dataStore.emailsToNames[email];
          recentContactData.emailsToNames[email] = name;

          // names to emails
          recentContactData.namesToEmails[name] = [...this.dataStore.namesToEmails[name]];

          // emails to pictures
          recentContactData.emailsToProfileImageUrls[email] = this.dataStore.emailsToProfileImageUrls[email];
        });
        emailDataString = JSON.stringify(recentContactData);

        // still too long?
        if (emailDataString.length >= localStorageLimit) {
          return false;
        }
      }

      localStorage.setItem(this.localStorageKey, emailDataString);
      return true;
    },

    // print debug message
    debug(...message) {
      if (this.noDebug) {
        return;
      }
      console.log('Autocomplete email component:', ...message);
    },

    // load cached email data
    loadCachedContacts() {
      const localDataString = localStorage.getItem(this.localStorageKey);
      if (!localDataString) {
        return false;
      }
      const localData = JSON.parse(localDataString);
      Object.keys(localData)
        .forEach((key) => this.dataStore[key] = localData[key]);
      return true;
    },

    async deleteEmailOptionClick(email) {
      const resp = await fetch('data?account-updateautocompleteemails-hideemail', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          requesttype: 'account-updateautocompleteemails-hideemail',
          emailtohide: email,
        }),
        withCredentials: true,
      });

      if (!resp.ok) {
        this.debug('could not hide an email');
        return null;
      }
      await this.fetchDeletedEmails();

      this.$buefy.snackbar.open({
        duration: 10000,
        message: `${email} has been deleted from your saved contacts!`,
        type: 'is-white',
        position: 'is-bottom',
        actionText: 'Undo',
        queue: false,
        onAction: async () => {
          const resp = await fetch('data?account-updateautocompleteemails-unhideemail', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              requesttype: 'account-updateautocompleteemails-unhideemail',
              emailtounhide: email,
            }),
            withCredentials: true,
          });

          if (!resp.ok) {
            this.debug('could not unhide an email');
            return null;
          }

          await this.fetchDeletedEmails();

          this.$buefy.snackbar.open({
            message: `Deletion undone!`,
            type: 'is-white',
          });
        },
      });

      return resp;
    },
  },

  computed: {
    matchingEmails() {
      const matches = this.matches.map((user) => user.email);
      let matchAndRecent;
      if (this.isAutoFocusLocal && this.dataStore?.recentEmails) {
        matchAndRecent = this.sanitizeArray([...matches, ...this.dataStore.recentEmails]);
      } else {
        matchAndRecent = matches;
      }
      for (let email of this.dataStore.deletedEmails) {
        const index = matchAndRecent.indexOf(email);
        if (index > -1) {
          matchAndRecent.splice(index, 1);
        }
      }

      if (this.orgUsersOnly) {
        matchAndRecent = matchAndRecent.filter((email) => this.dataStore.orgUsers.includes(email));
      }

      return matchAndRecent;
    },
    inputIcon() {
      if (!this.hideIconOnType || !this.emailStore?.length) return this.icon === undefined ? 'user-group' : this.icon;
      if (this.emailStore?.length) return null;
    },
  },

  mounted() {
    if (this.isAutoFocusLocal) {
      const value = this.currentUserEmailAutoFocus ? this.currentUserEmailAutoFocus : this.currentUserNameAutoFocus;
      this.$emit('email-input-keydown', value);
      this.keydownHandler(value);
      if (this.$refs.emailInputAutoComplete) {
        this.$refs.emailInputAutoComplete.focus();
        this.$forceUpdate();
      }
    }
  },

  async created() {
    // determine how entered emails will be stored
    this.usingArrayForEmailStorage = Array.isArray(this.$props.emailStore);

    this.$emit('email-load-initiated');

    const cacheLoadSuccess = this.loadCachedContacts();
    this.debug(cacheLoadSuccess ? 'loaded cached contacts' : 'no cached contacts to load');
    await this.loadAllAvailableEmails();
  },
};
</script>

<template>
  <div
    :class="['vue-email-input', { 'readonly': $attrs.readonly }]"
  >
    <b-field :label="label">
      <component
        :is="usingArrayForEmailStorage ? 'b-taginput' : 'b-autocomplete'"
        :disabled="disabled || false"
        :style="{ opacity: disabled ? 0.5 : 1 }"
        ref="emailInputAutoComplete"
        v-bind="$attrs"
        :icon="inputIcon"
        :icon-pack="iconPack || 'fal'"
        :allow-new="true"
        autocomplete
        :data="matchingEmails"
        dropdown-position="bottom"
        :confirm-keys="[' ', ',', 'Tab', 'Enter']"
        :open-on-focus="isAutoFocusLocal"
        aria-close-label="Delete this tag"
        :maxlength="500"
        :has-counter="false"
        :remove-on-keys="$attrs.readonly ? [] : ['Backspace']"
        maxtags="50"
        @input="onInputHandler"
        @select="dropdownClickHandler"
        @keydown.native.enter="enterKeyHandler"
        @blur="onBlurHandler"
        @focus="$emit('email-input-focus', $event)"
        @paste.native="onPasteHandler"
        @typing="(value) => { this.isAutoFocusLocal = false; $emit('email-input-keydown', value); keydownHandler(value) }"
      >
        <template slot-scope="props">
          <div class="email-input-option">
            <!-- profile picture or initials fallback -->
            <img
              v-if="dataStore.emailsToProfileImageUrls[props.option]"
              :style="profilePictureStyles"
              :src="dataStore.emailsToProfileImageUrls[props.option]"
            />
            <div
              v-else
              :style="profilePictureStyles"
              class="email-input-initials"
            >
              <p>{{ getUserInitials(dataStore.emailsToNames[props.option] || props.option) }}</p>
            </div>

            <p class="user-data">
              <!-- (username or em-dash) and email -->
              <span
                v-html="emphasizeSearchString(dataStore.emailsToNames[props.option]) || '—'"
                :style="{opacity: '.7', display: isCompact ? 'block' : null}"
                class="user-name"
              ></span>
              {{ ' ' }}
              <span v-html="emphasizeSearchString(props.option)"></span>
            </p>
            <div class="delete-email-option-btn" role="Button"
                 v-on:click.stop="deleteEmailOptionClick(props.option)">
              <b-icon pack="fal" icon="trash-can" class="icon-smallgrey" font-scale="0.6">
              </b-icon>
            </div>
          </div>
        </template>
      </component>
      <div v-if="withCcIcons && (!ccAdded || !bccAdded)" class="vue-email-input__add-ons">
        <span
          v-if="!ccAdded"
          class="cc"
          @click="() => {$emit('add-cc'); ccAdded = true;}"
        >
          Cc
        </span>
        <span
          v-if="!bccAdded"
          class="bcc"
          @click="() => {$emit('add-bcc'); bccAdded = true;}"
        >
          Bcc
        </span>
      </div>
    </b-field>

    <!-- recent emails tags -->
    <div
      class="recent-emails"
      v-if="!hideRecentTags && !$attrs.readonly && dataStore.recentEmails.length"
    >
      <!-- Recent email for self -->
      <b-tag v-if="showSelfInRecents && currentUserEmail" :style="{ cursor: 'pointer' }"
             @click="recentsTagClickHandler(currentUserEmail); currentUserEmail = ''">Me
      </b-tag>

      <b-tag
        v-for="email in sanitizeArray(dataStore.recentEmails).slice(0, !showingAllRecentEmails ? 2 : Infinity)"
        :key="email" :style="{ cursor: 'pointer' }"
        @click="recentsTagClickHandler(email)">{{ email }}
      </b-tag>

      <b-tag v-if="!showingAllRecentEmails && dataStore.recentEmails.length > 2"
             :style="{ cursor: 'pointer', marginRight: '1em' }" @click="showingAllRecentEmails = true">Show
        more
      </b-tag>
    </div>

  </div>
</template>
<style lang="postcss" scoped>
.vue-email-input {
  position: relative;
  >>> .dropdown-item {
    text-overflow: initial;
    padding-right: 3px;
  }
  >>> .field {
    position: relative;
  }
  >>> .field:has(+ .recent-emails) {
    margin-bottom: 5px;
  }
  .recent-emails {
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
  }
  &__add-ons {
    position: absolute;
    top: 11px;
    right: 12px;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    span {
      color: #333;
      cursor: pointer;
      &:hover {
        color: #1355FF;
      }
    }
  }
  &.readonly >>> .tag.is-delete {
    &:before, &:after {
      display: none;
    }
    width: 5px;
  }
  >>> .taginput:has(+ .vue-email-input__add-ons) {
    .taginput-container {
      padding-right: 56px;
    }
  }
  >>> .field.has-addons .control:not(:last-child) {
    margin-right: 0;
  }
}

.email-input-option {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.email-input-option .delete-email-option-btn {
  margin-left: auto;
  margin-right: 10px;
}

.icon-smallgrey {
  font-size: 10px;
  color: #474545;
}

.icon-smallgrey:hover {
  color: black;
}

.snackbar {
  background-color: #F4F6F7 !important;
}

.email-input-initials {
  width: 40px;
  height: 40px;
}
</style>
